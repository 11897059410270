// Padding
.no-p-l {padding-left: 0px;}
.no-p-r {padding-right: 0px;}
.no-p {padding: 0px;}

// XS
@media (max-width: 768px) {
.no-p-l-xs {padding-left: 0px;}
.no-p-r-xs {padding-right: 0px;}
.no-p-xs {padding: 0px;}
}

// SM
@media (min-width: 768px) and (max-width: 992px) {
.no-p-l-sm {padding-left: 0px;}
.no-p-r-sm {padding-right: 0px;}
.no-p-sm {padding: 0px;}
}

// MD
@media (min-width: 992px) and (max-width: 1200px) {
.no-p-l-md {padding-left: 0px;}
.no-p-r-md {padding-right: 0px;}
.no-p-md {padding: 0px;}
}

// LG
@media (min-width: 1200px) {
.no-p-l-lg {padding-left: 0px;}
.no-p-r-lg {padding-right: 0px;}
.no-p-lg {padding: 0px;}
}

// 5 Column Grid
.col-xs-five,
.col-sm-five,
.col-md-five,
.col-lg-five {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-five {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
.col-sm-five {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-five {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-five {
        width: 20%;
        float: left;
    }
}