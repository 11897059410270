@import "grid";

html, body {
	height: 100%;
}

body {
	font-family: 'Cutive Mono';
	padding: 0;
	margin: 0;
	color: #000;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@media(max-width: 980px) {
		overflow-x: hidden;
	}	
	
	&.sitekick {
		padding-top: 70px;
		
		.navigation-section {
			margin-top: 70px;
		}
	}
}

h1,h2,h3,h4,h5 {
	padding: 0;
	margin: 0;
	font-family: 'Special Elite', cursive;
	font-weight: normal;
}

.image-wrapper {
	img {
		max-width: 100%;
		height: auto;
	}
}

// Navigation
.navigation-section {
	width: 100%;
	height: 112px;
	background-color: #FFF;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
	font-size: 22px;
	font-weight: 600;
	position: fixed;
	top: 0;
	z-index: 999;
	
	@media(max-width: 1440px){
		height: 90px;
	}
	
	// Logo
	.logo {
		width: 60px;
		float: left;
		margin-top: 28px;
		margin-left: 30px;
			
		@media(max-width: 1440px){
			margin-top: 18px;
			margin-left: 20px;
		}		
	}

	#logo-ijsselstein {
		float: left;
		width: 190px;
		height: 45px;
		background: url('../img/logo_ontmoet_elkaar.jpg');
		margin: 42px 0px 0px 35px;

		@media(max-width: 1440px){
			margin: 30px 0px 0px 35px;
		}

		@media(max-width: 1080px){
			margin: 42px 0px 0px 25px;
			width: 127px;
			height: 30px;
			background-size: 127px 30px;
		}

		@media(max-width: 992px){
			float: none;
			position: absolute;
			top: 20px;
			left: 100px;
			margin: 0px;
		}

	}

	.hamburger{
		display: none;
	}

	// Main navigation
	ul.main-navigation {
		float: right;
		list-style-type: none;
		padding: 0;
		margin: 0;


		// List item
		li {
			float: left;
			margin-left: 15px;
			margin-right: 15px;
			position: relative;
			height: 112px;
			line-height: 112px;
								
			@media(max-width: 1440px){
				height: 90px;
				line-height: 90px;
			}

			@media(max-width: 1280px){
				margin-left: 10px;
				margin-right: 10px;
			}			

			&:last-child {
				background: #f8f8f8;
				border-left: 1px solid #dededb;
				padding: 0px 25px;
				margin-right: 0px;
				padding-right: 60px;
				background-image: url('../img/arrow.svg');
				background-size: 29px 26px;
				background-repeat: no-repeat;
				background-position: 90% center;
				-webkit-transition: .3s;
				transition: .3s;
				color: #3d3d3d;
				cursor: pointer;
				&:hover{
					background-position: 95% center;
				}				
			}
			
			&.active {
				a {
					color: #a8af4a;
				}
			}
			
			// List item A attribute
			a {
				font-family: 'Special Elite', cursive;
				font-weight: normal;
				color: #3d3d3d;
				cursor: pointer;
				padding: 7px 10px;
				-webkit-border-radius: 5px;
				border-radius: 5px;

				@media(max-width: 1280px){
					font-size: 18px;
				}					
				
				.reservation{
					background-color: #000;
				}

				// Link hover
				&:hover {
					color: #a8af4a;
					text-decoration: none;
				}
			}

			// Sub navigation
			ul.sub-navigation {
				position: absolute;
				left: -15px;
				padding: 0;
				margin: 0;
				width: 200px;
				display: none;
				padding-top: 40px;

				// Sub navigation list item
				li {
					display: block;
					margin: 0;
					a {
						display: inline-block;
						padding: 10px 15px;
						width: 100%;
						border-bottom: 1px solid rgba(0,0,0,0.1);
						background-color: #FFF;
					}

					// Rounded borders sub nav
					&:first-child a {border-top-left-radius: 5px;border-top-right-radius: 5px;}
					&:last-child a {border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;border: none;}
				}
			}
		}
	}
}
@media (max-width: 992px) {
	// Navigation section
	.navigation-section {
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 0px;

		// Logo
		.logo {
			width: 50px;
			float: none;
			margin-top: 10px;
			margin-left: 20px;
		}

		.hamburger{
		    display: inline-block !important;
		    float: right;
		    font-size: 19px;
		    border-radius: 9px;
		    padding-left: 15px;
		    margin-top: 20px;
		    margin-right: 15px;
		}

		// Main navigation
		ul.main-navigation {
			float: left;
			display: none;
			background: #FFF;
			width: 100%;

			// List item
			li {
				float: left;
				width: 100%;
				display: block;
				margin-left: 0px;
				margin-right: 0px;
				padding-left: 20px;
				border-bottom: 1px solid rgba(0,0,0,.1);
				height: 60px;
				line-height: 60px;
				
				&:last-child {
					padding: 0px 0px 0px 20px;
					border: 0px;
				}
				
				// List item A attribute
				a {
					display: inline-block;
					width: 100%;
					padding: 0px;
				}

				&:last-child a {border: none; backgound: none;}

				// Sub navigation
				ul.sub-navigation {
					position: relative;
					left: 0px;
					width: 100%;
					padding-top: 0px;
				}
			}
		}
	}
}


.button{
	font-family: 'Special Elite', cursive;
	font-size: 24px;
	font-weight: normal !important;
	color: #000;
	text-decoration: none;
	padding-top: 15px;
	padding-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 40px;
	margin-top: 40px;
	display: inline-block;
	transition: all .3s;
	background-image: none;
	background-repeat: no-repeat;
	background-position: right center;
	background-color: #a1a936;
	
	&:hover{
		text-decoration: none;
		color: #000;
		cursor: pointer;
	}
	&.button-party{
		background-color: #40372e;
		margin-bottom: 110px;
		border-color: #40372e;
		color: #fff;
		@media(max-width:767px){
			font-size: 18px;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 8px;
			padding-bottom: 5px;
			margin-bottom: 69px;
		}
		&:after{
		    position: absolute;
		    background-position: center center ;
		    background-repeat: no-repeat;
			background-image: url('../img/arrow-white.svg');
			background-size: 22px 20px;
		    content: "";
		    width: 30px;
		    z-index: 2;
		    height: 30px;
		    opacity: 0;
		    transition: 0.3s;
		}
		&:hover{
			padding-left: 7px;
			padding-right: 33px;
			&:after {opacity: 1;}
		}
	}
	&.button-reservation {
		font-family: 'Special Elite', cursive;
		font-size: 24px;
		display: inline-block;
		color: #000;
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 15px;
		padding-bottom: 10px;
		background-color: #a1a936;
		margin-top: 10px;
		margin-bottom: 20px;
		@media(max-width:767px){
			font-size: 18px;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 8px;
			padding-bottom: 5px;
		}
		&:after{
		    position: absolute;
		    background-position: center center ;
		    background-repeat: no-repeat;
			background-image: url('../img/arrow.svg');
			background-size: 22px 20px;
		    content: "";
		    width: 30px;
		    z-index: 2;
		    height: 30px;
		    opacity: 0;
		    transition: 0.3s;
		}
		&:hover{
			padding-left: 20px;
			padding-right: 40px;
			&:after {opacity: 1;}
		}
	}
	&.button-header-menu{
		background-color: #a1a936;
		border-color: #a1a936;
		transition: 0.3s;
		position: relative;
		&:after{
		    position: absolute;
		    background-position: center center ;
		    background-repeat: no-repeat;
			background-image: url('../img/arrow.svg');
			background-size: 22px 20px;
		    content: "";
		    width: 30px;
		    z-index: 2;
		    height: 30px;
		    opacity: 0;
		    transition: 0.3s;
		}
		&:hover{
			padding-left: 7px;
			padding-right: 33px;
			&:after {opacity: 1;}
		}
	}
	&.button-reservation-menu{
		background-color: #423930;
		border-color: #423930;
		color: #fff;
		&:after{
		    position: absolute;
		    background-position: center center ;
		    background-repeat: no-repeat;
			background-image: url('../img/arrow-white.svg');
			background-size: 22px 20px;
		    content: "";
		    width: 30px;
		    z-index: 2;
		    height: 30px;
		    opacity: 0;
		    transition: 0.3s;
		}
		&:hover{
			padding-left: 7px;
			padding-right: 33px;
			&:after {opacity: 1;}
		}
	}
	&.button-route{
		font-family: 'Special Elite', cursive;
		font-size: 24px;
		font-weight: normal;
		display: inline-block;
		color: #000;
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 15px;
		padding-bottom: 10px;
		background-color: #a1a936;
		margin-top: 28px;
		margin-bottom: 69px;
		@media(max-width:767px){
			font-size: 18px;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 8px;
			padding-bottom: 5px;
		}
		&:after{
		    position: absolute;
		    background-position: center center ;
		    background-repeat: no-repeat;
			background-image: url('../img/arrow.svg');
			background-size: 22px 20px;
		    content: "";
		    width: 30px;
		    z-index: 2;
		    height: 30px;
		    opacity: 0;
		    transition: 0.3s;
		}
		&:hover{
			padding-left: 20px;
			padding-right: 40px;
			&:after {opacity: 1;}
		}
	}
}

.header-container {
	margin-top: 112px;
	
	@media(max-width: 1440px) {
		margin-top: 90px;
		height: 350px !important;
	}		
	
	@media(max-width: 768px) {
		height: 200px !important;
	}		
}

header.header-home {
	position: relative;
	width: 100%;
	height: -webkit-calc(100% - 112px);
	height: calc(100% - 112px);	
	margin-top: 112px;
	background-image: url('../img/header-home.jpg');
	background-repeat: no-repeat;
	background-position: bottom 71% center;
	background-size: cover;
	
	@media(max-width: 1440px) {
		margin-top: 90px;
		height: -webkit-calc(100% - 90px);
		height: calc(100% - 90px);
	}
	
	@media(max-width: 992px) {
		background-image: url('../img/header-home.jpg');
		height: 600px;
		overflow-x: hidden;
	}
	
	@media(max-width: 640px) {
		background-image: url('../img/header-home.jpg');
		height: 400px;
	}
	
	#illy {
		position: absolute;
		width: 335px;
		height: 102px;
		background: url('../img/illy.svg');
		background-size: 335px 102px;
		bottom: 30px;
		left: 30px;
		
		@media(max-width: 992px) {
			-webkit-transform: scale(0.8);
			transform: scale(0.8);
			bottom: 20px;
			left: 10px;			
		} 

		@media(max-width: 640px) {
			-webkit-transform: scale(0.5);
			transform: scale(0.5);
			left: 0px;
			bottom: 0px;
			margin-left: -70px;
			margin-bottom: -10px;
		} 		
	}
	
	.header-circle {
		position: absolute;
		width: 599px;
		height: 599px;
		opacity: 0;
		text-align: center;
		background-image: url('../img/header-circle.png');
		background-repeat: no-repeat;
		background-position: center center;
		padding: 60px 0px 0px 0px;
		left: 50%;
		top: 70%;
		-webkit-transform: translateX(-50%) translateY(-50%);		
		transform: translateX(-50%) translateY(-50%);
		-webkit-transition: all 0.5s ease;
		transition: all 1s ease;
		
		@media(max-width: 1440px){
			-webkit-transform: translateX(-50%) translateY(-50%) scale(0.80);
			transform: translateX(-50%) translateY(-50%) scale(0.80);
		}
		
		@media(max-width: 1280px){
			-webkit-transform: translateX(-50%) translateY(-50%) scale(0.7);
			transform: translateX(-50%) translateY(-50%) scale(0.7);
		}		
		
		.button {
	
			@media(max-width: 640px){
				clear: left;
				-webkit-transform: scale(1.2);
				transform: scale(1.2);	
				display: block;
				width: 160px;
				margin: auto;
			}	
		}			
		
		&.fadeInTop {
			top: 50%;
			opacity: 1;
		}
		
		h3{
			color: #fff;
			font-size: 30px;
		}
		.dotted-line{
			margin-top: 20px;
			margin-bottom: 20px;
			
			@media(max-width: 767px) {
				display: none;
			}
		}
		.logo{
			width: 180px;
		}
		@media(max-width: 767px) {
			background-size: 375px;
			.logo{
				width: 80px;
				margin-top: 90px;
			}
			h3{
				font-size: 20px;
				padding-top: 15px;
			}
			.button{
				font-size: 14px;
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 5px;
				padding-top: 8px;
				margin-top: 20px;
			}
			margin-top: 20px; 
			margin-bottom: 20px;
		}
	}
}

main {
	@media(max-width: 640px){
		overflow-x: hidden;
	}	
}

.flex {
	display:-webkit-flex;
	display:-webkit-box;
	display: flex;
}
 
.flex-row {
	.row {
		display:-webkit-flex;
		display:-webkit-box;
		display: flex;
	}	
}

@media(max-width:992px) {
	.flex{
		display: block;
	}
}

.intro{
	padding-top: 100px;
	padding-bottom: 80px;
	@media(max-width:992px){
		padding-top: 20px;
		padding-bottom: 40px;
	}
	h2{
		font-size: 40px;
		margin-bottom: 30px;
		@media(max-width:767px){
			font-size: 24px;
		}
	}
	p{
		font-family: 'Josefin Sans', sans-serif;
		font-size: 18px;
		font-weight: 600;
		line-height: 30px;
		@media(max-width: 992px) {
			padding-bottom: 40px;
			font-size: 18px;
		}
	}
	.dinner-and-lunch{
		text-align: center;
		margin-bottom: 50px;
		position: relative;
		img{
			width: 100%;
			height: auto;
		}
		.menucard-img{
			max-width: 193px;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			margin: auto auto;
			@media(max-width: 500px){
				width: 150px;
				height: 150px;
			}
		}
	}
	
	.image {
		img {
			max-width: 100%;
		}
	}
}

.divider {
	background: url('../img/dotted_bg.png') center repeat-y;
	
	@media(max-width: 992px) {
		background: none; 
	}
}

.col {
	.inner {
		padding: 0px 40px;
		
		@media(max-width: 768px) {		
			padding: 0px;
		}
	}
}

.actionmenu{
	.actionmenu-img{
	
		background-image: url('../img/actiemenu.jpg');
		background-repeat: no-repeat;
		background-position: center;
		
		img {
			@media(max-width: 992px){
				width: 100%;
			}	
		}
		
		@media(max-width: 992px){
			background-image: none;
		}
	}

	.actionmenu-right{
		text-align: center;
		background: #0c1013;
		color: #fff;
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 80px;
		padding-bottom: 30px;
		@media(max-width:767px){
			padding-top: 40px;
		}
		h2{
			font-size: 52px;
			padding-bottom: 20px;
			@media(max-width:767px){
				font-size: 34px;
			}
		}
		h3{
			font-size: 34px;
			
			padding: 10px 0px 10px 0px;
			margin: 0px;
			
			&.prijs{
				padding-top: 50px;
				@media(max-width:767px){
					font-size: 34px;
				}		
			}
		}
		h4{
			font-size: 17px;
			margin-bottom: 20px;
		}
		p{
			font-family: 'Josefin Sans', sans-serif;
			font-size: 20px;
			font-weight: 600;
			padding: 0px 0px 15px 0px;
			margin: 0px;
		}
		.foodfont{
			font-family: 'Josefin Sans', sans-serif;
			font-style: italic;
			font-size: 18px;
		}
		
		div {
			position: relative;
			z-index: 2;
		}	

		&:after {
			content: "";
			position: absolute;
			width: -webkit-calc(100% - 24px);		
			width: calc(100% - 24px);
			height: -webkit-calc(100% - 24px);
			height: calc(100% - 24px);
			margin: 12px;
			border: 2px dashed #23272a;
			top: 0px;
			left: 0px;
			z-index: 0;
		}		
	}
}

.blocks.reviews .slick-list {
	padding-top: 50px;
}

.blocks{
	padding-top: 100px;
	padding-bottom: 75px;

	@media (min-width: 1025px) and (max-width: 1600px) {
		.col-lg-10.col-lg-offset-1 {
			margin: 0!important;
			width: 100%!important;

			// Paragraph
			p {
				font-size: 19px;
			}
		}
	}

	.slick-slide {
		div {
			text-align: center;

			img {
				margin: auto;
				padding-bottom: 20px;

				&.quill {
					margin-top: -110px;
				}

				@media(max-width: 1400px) {
					padding-bottom: 10px;
				}
			}
		}
	}

	.mobile-slider {
		display: none;

		@media(max-width: 992px) {
			display: block;
		}
	}

	.quill {
		width: 140px !important;
		height: auto !important;
	}
	
	.block1{
		// margin-bottom: 75px;
		border: 4px solid #000;
		padding-top: 63px;
		padding-bottom: 86px;
		text-align: center;
		background-color: #dad6ce;
		padding-left: 25px;
		padding-right: 25px;
		height: 550px;
		
		@media(max-width: 1400px){
			height: 650px;
		}		
		
		img{
			height: 120px;
			padding-bottom: 60px;
		}
		p{
			font-size: 21px;
			font-family: 'Special Elite';
			@media(max-width: 1260px) {
				font-size: 18px;
			}
		}
		&.block-400{
			height:420px;
		}
		&.block-13{
			@media(max-width: 1200px){
				font-size: 13px;
			}
		}
		@media(max-width: 1200px) {
			height: 550px;
		}
		@media(max-width: 980px) {
			height: auto;
			min-height: 300px;
		}		
	}
	
	.block-video {
		border: 4px solid #000;
		padding-top: 14px !important;
		padding-bottom: 14px !important;	
		padding-left: 15px !important;
		padding-right: 15px !important;
		
		a {
			float: left;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
		}
		
		img {
			width: 100%;
			padding-bottom: 0px !important;
		}
	}
	
	.block2{
		// margin-bottom: 75px;
		background-image: url('../img/scene-bg.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% 100%;
		text-align: center;
		padding-top: 67px;
		padding-bottom: 95px;
		padding-left: 25px;
		padding-right: 25px;
		height: 550px;
		font-family: 'Josefin Sans', sans-serif;
		font-size: 18px;
		
		@media(max-width: 1400px){
			height: 650px;
		}				
		
		img{
			height: 120px;
			padding-bottom: 60px;
		}
		p{
			font-size: 21px;
			font-family: 'Special Elite';
			@media(max-width: 1260px) {
				font-size: 18px;
			}
		}
		&.block-400{
			height:420px;
		}
		&.block-13{
			@media(max-width: 1200px){
				font-size: 13px;
			}
		}
		@media(max-width: 1200px) {
			height: 550px;
		}
		@media(max-width: 992px) {
			padding-left: 30px;
			padding-right: 30px;
		}
		@media(max-width: 980px) {
			height: auto;
			min-height: 300px;
		}		
		&.block2-quill{
			padding-top: 40px;
		}
	}
	.block3{
		// margin-bottom: 75px;
		border: 4px solid #000;
		padding-top: 63px;
		padding-bottom: 78px;
		text-align: center;
		padding-left: 25px;
		padding-right: 25px;
		height: 550px;
		
		@media(max-width: 1400px){
			height: 650px;
		}				
		
		img{
			height: 120px;
			padding-bottom: 60px;
		}
		p{
			font-size: 21px;
			font-family: 'Special Elite';
			@media(max-width: 1260px) {
				font-size: 18px;
			}
		}
		&.block-400{
			height:420px;
		}
		@media(max-width: 1200px) {
			height: 550px;
		}
		@media(max-width: 980px) {
			height: auto;
			min-height: 300px;
		}				
	}
	
	.block-js {
		opacity: 0;
		-webkit-transition: all 1s ease;
		transition: all 1s ease;
		margin-top: 50px;
		
		&.fadeIn {
			opacity: 1;
			margin-top: 0px;
		}
		
		@media(max-width: 992px) { 
			opacity: 1;
			margin-top: 0px;			
		}
	}
	
	.quill{
		margin-top: -105px;
		&.quill2{
			margin-top: -73px;
		}
	}
	.name{
		padding-top: 10px;
	}
}

.party{
	background: #dad6ce;

	.party-img{
		
		background-image: url('../img/feestje.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		
		img {
			max-width: 100%;				
		
			@media(max-width: 1280px){
				margin-left: -250px;		
			}				
		}
		
		@media(max-width: 992px){
			background-image: none;
			background-size: 100% 100%;
		}
	}
	.party-right{
		position: relative;
		text-align: center;
		background: #dad6ce;
		color: #000;
		padding-top: 100px;
		@media(max-width:767px){
			padding-top: 40px;
		}
		h2{
			font-size: 42px;
			@media(max-width:767px){
				font-size: 28px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		h4{
			font-size: 17px;
			margin-bottom: 20px;
		}
		p{
			padding-left: 200px;
			padding-right: 200px;
			padding-bottom: 10px;
			font-family: 'Josefin Sans', sans-serif;
			font-weight: bold;
			font-size: 20px;
			@media(max-width:1730px){
				padding-left: 30px;
				padding-right: 30px;
			}
			@media(max-width:992px){
				padding-bottom: 0px;
				padding-left: 60px;
				padding-right: 60px;
			}
			@media(max-width:767px){
				padding-bottom: 0px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		
		.button {
			background: #000;
			color: #FFF;
		}
		
		div {
			position: relative;
			z-index: 2;
		}	

		&:after {
			content: "";
			position: absolute;
			width: -webkit-calc(100% - 24px);		
			width: calc(100% - 24px);
			height: -webkit-calc(100% - 24px);
			height: calc(100% - 24px);
			margin: 12px;
			border: 2px dashed #c0bfbf;
			top: 0px;
			left: 0px;
			z-index: 0;
		}		
	}
	@media(max-width: 1200px) {
		.feestje{
			p{
				padding-left: 50px;
				padding-right: 50px;
			}
		}
	}
}

.recensions{
	.recension1{
		background-image: url('../img/recensie-mail.png');
		background-repeat: no-repeat;
		text-align: center;
		margin-top: 100px;
		padding-left: 25px;
		padding-rig: 25px;
		margin-bottom: 75px;
		h3{
			padding-top: 185px;
			padding-bottom: 20px;
		}
		p{
			padding-bottom: 60px;
		}
	}
}

.foodhall {
	background: #0c1013;

	.carousel {
		img {
			@media (max-width: 992px) {
				width: 100%;
			}		
		}
	}
	
	.foodhall-img {
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		width:100%;
		bottom: 0;
		top: 0;
		height: 100%;
		position: absolute;
		@media (max-width: 992px) {
			display: none;
		}
	}
	.foodhall-right{
		position: relative;
		background: #0c1013;
		text-align: center;
		color: #fff;
		font-family: 'Josefin Sans', sans-serif;
		padding-top: 100px;
		font-size: 18px;
		
		@media(max-width:767px){
			padding-top: 40px;
			margin-top: -1px;
		}
		h2{
			font-size: 42px;
			@media(max-width:767px){
				font-size: 28px;
			}
		}
		h4{
			font-size: 17px;
			margin-bottom: 20px;
		}
		p{
			padding-left: 200px;
			padding-right: 200px;
			padding-bottom: 40px;
			font-weight: bold;
			font-size: 20px;
			
			@media(max-width:1407px){
				padding-left: 30px;
				padding-right: 30px;
			}
			@media(max-width:992px){
				padding-bottom: 0px;
				padding-left: 60px;
				padding-right: 60px;
			}
			@media(max-width:767px){
				padding-bottom: 0px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		
		.button {
			background: #a1a936;
		}
		
		div {
			position: relative;
			z-index: 2;
		}	

		&:after {
			content: "";
			position: absolute;
			width: -webkit-calc(100% - 24px);		
			width: calc(100% - 24px);
			height: -webkit-calc(100% - 24px);
			height: calc(100% - 24px);
			margin: 12px;
			border: 2px dashed #23272a;
			top: 0px;
			left: 0px;
			z-index: 0;
		}				
	}
	
	.slick-dots {
		list-style: none;
		bottom: 20px;
		
		li {
			button {
				width: 16px;
				height: 16px;
				border: 3px solid #FFF;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				
				&::before {
					content: "";
				}
				

			}
			
			&.slick-active {
				button {
					background: #FFF;
				}	
			}			
		}
	}		
	
	.carousel-indicators {
		li{
			width: 17px;
			height: 17px;
			border: 2px solid #FFF;
			
			&.active {
				width: 19px;
				height: 19px;
			}
		}
	}
	
	@media(max-width: 1200px) {
		.eetzaal{
			p{
				padding-left: 50px;
				padding-right: 50px;
			}
		}
	}
}

.packages {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 18px;
	font-weight: 600;
	
	.package {
		padding: 40px 0px;
		border-top: 3px dotted #b3b3b3;
		
		&:last-child {
			border-bottom: 3px dotted #b3b3b3;
		}
		
		img {
			width: 100%;
		}
	}

}

.empty-space-top {
	padding-top: 65px;
	
	@media(max-width: 768px) {
		padding-top: 10px; 
	}
}

footer{
	background-color: #dad6ce;
	text-align: center;
	padding-top: 60px;
	.mobile-dotted{
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
		margin-bottom: 60px;
	}
	
	a {
		color: #000;
	}
	
	.footer-section-left{
		font-family: 'Josefin Sans', sans-serif;
		font-size: 21px;
		font-weight: 600;
		
		img{
			width: 180px;
			max-width: 100%;
			height: auto;
			display: block;
			margin-bottom: 40px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.footer-section-middle{
	
		border-left: 3px dotted #b5b1ab;
		border-right: 3px dotted #b5b1ab;	
		max-height: 500px;	
	
		@media(max-width: 1280px) {
			border: 0px;
			max-height: none !important;
		}
	
		h2{
			font-size: 36px;
			font-family: 'Special Elite', cursive;	
			font-weight: normal;
			letter-spacing: -2px;
			padding-bottom: 20px;
			padding-top: 0px;
		}
		p{
			font-size: 21px;
			font-family: 'Josefin Sans', sans-serif;
			font-weight: 600;
		}
		
		.button-reservation {
			@media(max-width: 992px){
				margin: 0px;
			}
		}
	}
	.footer-section-right{
		margin-bottom: 60px;
		
		.social-icon {
			width: 70px;
		}
		
		h2{
			padding-top: 0px;
			font-size: 36px;
			font-family: 'Special Elite', cursive;
			font-weight: normal;
			letter-spacing: -2px;
			padding-bottom: 60px;
		}
		img{
			margin-bottom: 40px;
		}
		p{
			font-size: 21px;
			font-family: 'Josefin Sans', sans-serif;
			font-weight: 600;
			padding-bottom: 30px;
		}
	}
	
	.copyright {
		padding: 20px 0px;
	}
}

.cookie-notice {
	position: fixed;
	width: 100%;
	height: 70px;
	background: rgba(0,0,0,0.8);
	bottom: 0;
	left: 0;
	z-index: 98;
	text-align: center;

	@media(max-width: 768px) {
		height: 90px;
	}

	p {
		font-family: Helvetica, Arial, sans-serif;
		color: #FFF;
		font-size: 16px;
		position: relative;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		padding: 0;
		margin: 0;

		.btn {
			display: inline-block;
			background: #a1a936;
			padding: 7px 15px;
			margin: 0px 15px;
		}

		a {
			text-decoration: underline;
			color: #FFF;
		}
	}
}

.follow-up{
	background-image: url('../img/header-hightea.jpg');
	background-repeat: no-repeat;
	.header-circle{
		text-align: center;
		background-image: url('../img/header-circle-vervolg.png');
		background-repeat: no-repeat;
		background-position: center center;
		padding-top: 70px;
		padding-bottom: 70px;
		h3{
			color: #fff;
			font-size: 30px;
		}
		.dotted-line{
			margin-top: 20px;
			margin-bottom: 20px;
		}
		.logo{
			width: 150px;
			margin-top: 10px;
		}
		@media(max-width: 767px) {
			background-size: 375px;
			.logo{
				width: 80px;
			}
			h3{
				font-size: 20px;
			}
		}
	}
}

.custom-slider{
	display: none;
	@media(max-width: 992px) {
		display: block;
	}
}

.text-center{
	height: 400px;
	padding-top: 55px;
	&.text-center-300{
		height: 300px;
	}
	&.center2{
		padding-top: 15px;
	}
	&.center3{
		padding-top: 80px;
	}
	&.center4{
		padding-top: 70px;
	}
}

.carousel-control{
	color: #000;
	&.left{
		background-image: none;
	}
	&.right{
		background-image: none;
	}
	&.carousel-arrow{
		background-color: #fff;
	}
}

.footer-section-right input {
    width: 70%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    outline: none;
    outline-style: none;
    border-radius: 2px;
    border: none;
    color: #000;
    font-weight: bold;
	
	&.input-error {
		background: #cb8486;
		color: #FFF;
	}
}
.footer-section-right button {
    bottom: 0;
    right: 11%;
    position: absolute;
    border: none;
    background-color: transparent;
    width: 50px;
    height: 40px;
	background-image: url('../img/arrow.svg');
	background-size: 22px 20px;
    background-color: #a8af4a;
    background-repeat: no-repeat;
    background-position: center center;
    outline: none;
    outline-style: none;
    transition: .3s;
    @media(max-width: 992px){
    	right: 12%;
    }
    &:hover{
    	background-position: 70% center;
    }
}

.carousel-inner>.item>a>img, .carousel-inner>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img{
	margin-bottom: -2px;
}

.content {
	padding: 50px 0px;
	font-family: 'Josefin Sans', sans-serif;
	font-size: 18px;
	font-weight: 600;
	
	.button {
		font-family: 'Special Elite', cursive;
		font-size: 24px;
		display: inline-block;
		color: #000;
		font-weight: normal;
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 15px;
		padding-bottom: 10px;
		margin-top: 10px;
		margin-button: 0px;
		background-color: #a1a936;
		text-decoration: none;
		
		@media(max-width:767px){
			font-size: 18px;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 8px;
			padding-bottom: 5px;
		}
		&:after{
		    position: absolute;
		    background-position: center center ;
		    background-repeat: no-repeat;
			background-image: url('../img/arrow.svg');
			background-size: 22px 20px;
		    content: "";
		    width: 30px;
		    z-index: 2;
		    height: 30px;
		    opacity: 0;
		    transition: 0.3s;
		}
		&:hover{
			padding-left: 20px;
			padding-right: 40px;
			&:after {opacity: 1;}
		}		
	}	
	
	a {
		color: #000;
		text-decoration: underline;
	}
	
	h2 {
		font-size: 32px;
		padding-bottom: 20px;
		margin: 0px;
	}

	.border-right {
		border-right: 3px dotted #b3b3b3;
	}	
			
	.cart {
		font-weight: 600;
				
		h2 {
			font-size: 32px;
			padding-bottom: 10px;
			margin: 0px;
		}
		
		p {
			font-size: 18px;
			padding: 10px 0px;
			margin: 0px;
		}
		
		.cart-list {
			float: left;
			clear: left;
			width: -webkit-calc(100% - 20px);			
			width: calc(100% - 20px);
			list-style: none;
			padding: 0px 0px 50px 0px;
			margin: 0px;
			
			li {
				float: left;
				width: 100%;
				clear: left;
				padding: 5px 0px;
				border-bottom: 3px dotted #b3b3b3;
				
				&.comment {
					border: 0px;
					
					p {
						margin-top: 50px;
					}
				}
				
				span {
					font-size: 18px;
					
					&:first-child {
						position: relative;
						background: #FFF;
						padding-right: 5px;
						bottom: -12px;
					}
					
					&:last-child {
						font-family: 'Special Elite', cursive;	
						font-weight: 400;	
						float: right;					
						position: relative;
						background: #FFF;
						padding: 0px 0px 0px 5px;
						margin-right: -2px;
						bottom: -15px;
					}
				}
			}
		}
	}
	
	#contact {
		margin-bottom: 30px; 
		
		.input-group {
			float: left;
			width: 100%;
			clear: both;
			padding: 0px 0px 25px 0px;
			
			label {
				font-family: 'Special Elite', cursive;
				font-weight: normal;	
				float: left;
				width: 100%;
				clear: both;
			}
			
			input {
				float: left;
				width: 475px;
				height: 46px;
				background: url('../img/input-bg.jpg');
				padding: 5px 15px;
				border: 0px;
				
				@media(max-width: 1280px) {
					width: 400px;
					background: url('../img/input-bg-sm.jpg');
				}		

				@media(max-width: 540px) {
					width: 260px;
					background: url('../img/input-bg-xs.jpg');
				}					
				
				&.input-error {
					background: url('../img/input-bg-error.jpg');
					
					@media(max-width: 1280px) {
						background: url('../img/input-bg-error-sm.jpg');
					}	

					@media(max-width: 540px) {
						background: url('../img/input-bg-error-xs.jpg');
					}					
				}
			}
			
			textarea {
				float: left;
				width: 475px;
				height: 189px;
				background: url('../img/textarea-bg.jpg');
				padding: 10px 15px;
				border: 0px;	

				@media(max-width: 1280px) {
					width: 400px;
					background: url('../img/textarea-bg-sm.jpg');
				}	

				@media(max-width: 540px) {
					width: 260px;
					background: url('../img/textarea-bg-xs.jpg');
				}					
				
				&.input-error {
					background: url('../img/textarea-bg-error.jpg');
					
					@media(max-width: 1280px) {
						background: url('../img/textarea-bg-error-sm.jpg');
					}	

					@media(max-width: 540px) {
						background: url('../img/textarea-bg-error-xs.jpg');
					}						
				}				
			}

			&.checkbox {
				display: flex;
				align-items: center;
				input {
					width: auto;
					margin-right: 15px;
				}
			}


			&.input-date {
			
				.input {
					padding: 0px;
					float: left;
					
					&:nth-child(1) {
						width: 133px;
								
						@media(max-width: 1280px) {
							width: 100px;
						}							
						
					}
					
					&:nth-child(2) {
						width: 152px;						
					}

					&:nth-child(3) {
						width: 100px;
						
						@media(max-width: 1280px) {
							clear: left;
							margin-top: 20px;
						}							
					}

					&:nth-child(4) {
						width: 89px;
						
						@media(max-width: 1280px) {
							margin-top: 20px;
						}								
					}					

					input, select {
						float: left;
						width: 141px;
						height: 44px;
						background: url('../img/select-bg.png');
						padding: 5px 10px;
						border: 0px;		

						&.input-error {
							background: url('../img/select-bg-error.png');		
						}	
					}

					.custom-select {

						float: left;
						width: 89px;
						height: 44px;
						padding: 0px;
						background: url('../img/select-small-bg.png');
						overflow-x: hidden;
						
						&.input-error {
							background: url('../img/select-small-bg-error.png');		
						}							

						select {
							float: left; 
							width: 110px;
							background: none;
							border: 0px;
							padding: 5px 10px;
						}	
					}					
				}
			}
		}
		
		button {
			background: #a1a936;
			color: #000;
			font-size: 24px;
			font-weight: normal;
			font-family: 'Special Elite', cursive;
			width: 168px;
			height: 58px;
			padding: 10px 0px 5px 0px;
			text-align: center;
			border: 0px;
			cursor: pointer;
		}		
	}
	
	address {
		line-height: 30px;
	}
}

.ui-datepicker {

	&.ui-widget-content {
		background: #FFF;
		color: #000;
	}
	
	.ui-datepicker-header {
		.ui-datepicker-title {
			select {
				color: #000;
			}
		}
	}
	
	.ui-datepicker-calendar {
		tr {
			td {
				a {
					background: #FFF;
					color: #000;
					border: 1px solid #EEE;
					
					&.ui-state-active {
						background: #a1a936;
						border-color: #a1a936;
					}
				}
			}
		}
	}
}	

#carousel-xs {
	position: relative;
	display: none;
	
	img {
		width: 100%;
	}
	
	.slick-dots {
		list-style: none;
		bottom: 20px;
		
		li {
			button {
				width: 16px;
				height: 16px;
				border: 3px solid #FFF;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				
				&::before {
					content: "";
				}
				

			}
			
			&.slick-active {
				button {
					background: #FFF;
				}	
			}			
		}
	}		
	
	.carousel-indicators {
		li{
			width: 17px;
			height: 17px;
			border: 2px solid #FFF;
			
			&.active {
				width: 19px;
				height: 19px;
			}
		}
	}	
		
	@media (max-width: 998px) {
		display: block;
	}	
	
}

@media (max-width: 998px) {
	.zaal-carousel {
		display: none !important;
	}
}

section.news {
	padding: 80px 0;

	@media screen and (max-width: 767px) {
		padding: 50px 0;
	}

	h1,h2 {
        font-size: 32px;
        line-height: 1;
        margin-bottom: 40px;
    }
}

.news-list {

	.row {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		flex-wrap: wrap;
		&:before,&:after {display: none;}
	}

	.news__col {
		margin-bottom: 30px;
	}
}
// News block
a.newsblock {
    display: inline-block;
    width: 100%;
	height: 100%;
	text-decoration: none!important;
	color: #3D3D3D;
	position: relative;
	padding-bottom: 90px;
	transition: all .3s;

	@media (hover: hover) {
		&:hover {
			transform: translateY(-5px);
		}
	}

	.news__image {
		display: inline-block;
		width: 100%;
		float: left;

		img {
			width: 100%;
			float: left;
		}
	}

	.news__heading {
		display: inline-block;
		width: 100%;
		float: left;
		margin: 30px 0 20px 0;

		h4 {
			color: #3D3D3D;
			font-size: 23px;
			line-height: 30px;
		}
	}

	.news__intro {
		font-size: 18px;
		line-height: 22px;
		display: inline-block;
		width: 100%;
		float: left;
		font-family: 'Josefin Sans', sans-serif;
	}

	.news__footer {
		display: inline-block;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;

		.button {
			margin: 0;
		}
	}
}

.pagination-nav {

	// List
	.pagination {
		list-style-type: none;
		padding: 0;
		margin: 0;

		// Item
		.page-item {
			display: inline-block;
			width: auto;
			float: left;
			margin-right: 0.8rem;

			// Link
			a {
				width: 48px;
				height: 48px;
				border: 1px solid rgba(#000,0.1);
				text-align: center;
				line-height: 48px;
				color: #000;
				font-weight: 700;
				text-decoration: none;
				display: inline-block;
				transition: all .3s;
				font-family: 'Special Elite', cursive;
				font-size: 18px;
			}

			// Active
			&.active {

				// Link
				a {
					background-color: #a1a936;
					color: #fff;
				}
			}

			@media (hover: hover) {
				// Link
				a:hover {
					background-color: #a1a936;
					color: #fff;
				}
			}
		}
	}
}

.newsitem {
	margin-top: 112px;
	padding: 80px 0;
	position: relative;

	@media(max-width: 1440px) {
		margin-top: 90px;
	}

	.newsitem__goback {
		position: absolute;
		top: -65px;

		a {
			position: relative;
			display: inline-block;
			font-size: 18px;
			line-height: 22px;
			font-family: 'Josefin Sans', sans-serif;
			color: #3D3D3D;
			padding-left: 15px;

			&:before {
				background-image: url('/brasserie1560/img/arrow-left.svg');
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% 100%;
				position: absolute;
				left: 0;
				top:0 ;
				bottom: 0;
				margin: auto 0;
				width: 5px;
				height: 9px;
				content: '';
			}
		}
	}

	.newsitem__heading {
		display: inline-block;
		width: 100%;
		margin-bottom: 20px;

		h1 {
			font-size: 32px;
			line-height: 48px;
		}
	}

	.newsitem__image {
		display: inline-block;
		width: 100%;
		margin-bottom: 30px;

		img {
			width: 100%;
		}
	}

	.newsitem__content {
		font-size: 18px;
		line-height: 22px;
		font-family: 'Josefin Sans', sans-serif;

		iframe {
			width: 100%;
			max-width: 100%;
			margin-bottom: 30px;
		}

		img {
			width: 100%;
			max-width: 100%;
		}
	}
}

.items-more {
    padding-top: 0!important;

    .items-more-heading {
        position: relative;

		h2 {
			@media screen and (max-width: 767px) {
				margin-bottom: 15px!important;
			}
		}

        .button {
            background: transparent;
            border: 1px solid #DAD6CE;
            right: 0;
			top: 0;
			position: absolute;
            font-size: 18px;
            line-height: 1;
            margin: 0;

			@media screen and (max-width: 767px) {
				margin-bottom: 15px;
				position: static;
			}
        }
    }
}

// iPad
// @media (max-height: 768px) and (max-width: 1024px){
// 	.foodhall{
// 		.eetzaal-img{
// 			padding-bottom: 590px;
// 		}
// 	}
// 	.party{
// 		.feestje-img{
// 			padding-bottom: 635px;
// 		}
// 	}
// 	.actiemenu{
// 		.actiemenu-img{
// 			padding-bottom: 705px;
// 		}
// 	}
// }
